<template>
  <div :class="['video',{ 'hide-overlay': !videoOverlay }]">
    <vue-plyr :options="options">
      <template v-if="videoType === 'html5'">
        <slot/>
      </template>

      <div v-else :data-plyr-provider="videoType" :data-plyr-embed-id="videoKey"></div>
    </vue-plyr>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr'

export default {
  name: 'Video',
  components: {
    VuePlyr
  },
  props: {
    videoType: {
      type: String,
      default: 'html5',
      validator: v => ['html5', 'youtube', 'vimeo']
    },
    videoKey: {
      type: String,
      default: null
    },
    videoOverlay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      options: {
        // visit: https://github.com/sampotts/plyr#options
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vue-plyr/dist/vue-plyr.css';

$video-margin-bottom: $spacer !default;

$video-poster-bg: rgba($primary, 0.8) !default;

$video-controls-padding-y: $spacer * 0.2 !default;
$video-controls-padding-x: $spacer * 0.2 !default;
$video-controls-bg: rgba($black, 0.75) !default;

$video-control-bg: $primary !default;
$video-control-color: $white !default;
$video-control-hover-bg: $video-control-bg !default;
$video-control-hover-color: $video-control-color !default;

$video-playoverlay-size: $spacer * 5 !default;
$video-playoverlay-border: 2px solid $white !default;
$video-playoverlay-box-shadow-spread: 25px !default;
$video-playoverlay-box-shadow-bg: rgba($black, 0.4)  !default;
$video-playoverlay-box-shadow: 0 0 $video-playoverlay-box-shadow-spread ($video-playoverlay-box-shadow-spread * 0.3) $video-playoverlay-box-shadow-bg !default;

$video-tooltip-bg: rgba($white, 0.9) !default;

$video-menu-bg: $video-tooltip-bg !default;
$video-menu-radio-bg: $custom-control-indicator-bg !default;
$video-menu-radio-checked-outer-bg: $custom-control-indicator-checked-bg !default;
$video-menu-radio-checked-inner-bg: $custom-control-indicator-checked-color !default;

.video {
  margin-bottom: $video-margin-bottom;
  &:not(.hide-overlay){
    .plyr {
      .plyr__poster {
        display: none;
        z-index: 4;
      }
      .plyr__poster:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $video-poster-bg;
        }
      }
  }
  .plyr {
    .plyr__control.plyr__control--overlaid {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: $video-playoverlay-size;
      height: $video-playoverlay-size;
      background: none;
      background-color: $video-playoverlay-box-shadow-bg;
      color: $video-control-color;
      box-shadow: $video-playoverlay-box-shadow;
      z-index: 5;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: $video-playoverlay-border;
        border-radius: 100%;
      }

      &:hover {
        background-color: $video-playoverlay-box-shadow-bg;
      }
    }

    .plyr__controls {
      padding: $video-controls-padding-y $video-controls-padding-x;
      background: none;
      background-color: $video-controls-bg;

      .plyr__control {
        border-radius: 0;
        color: $video-control-color;

        &[aria-expanded=true] {
          background: $video-control-bg;
          color: $video-control-color;
        }

        &:hover {
          background: $video-control-hover-bg;
          color: $video-control-hover-color;
        }
      }
    }

    .plyr__progress,
    .plyr__volume {
      input[type=range] {
        color: $video-control-bg;

        &::-webkit-slider-thumb {
          background: $video-control-color;
        }

        &::-moz-range-thumb {
          background: $video-control-color;
        }
      }
    }

    .plyr__tooltip {
      background: $video-tooltip-bg;
      border-radius: 0;
      color: ontrast-color($video-tooltip-bg);

      &:before {
        border-top-color: $video-tooltip-bg;
      }
    }

    .plyr__menu__container {
      background: $video-menu-bg;
      border-radius: 0;

      .plyr__control {
        color: contrast-color($video-menu-bg);

        &:hover {
          background: rgba($video-control-hover-bg, 0.5);
        }

        &[role=menuitemradio] {
          &:before {
            border-radius: 0;
            @include make-leaf($border-radius-sm);
            background: $video-menu-radio-bg;
          }

          &[aria-checked=true] {
            &:before {
              background: $video-menu-radio-checked-outer-bg;
            }

            &:after {
              background: $video-menu-radio-checked-inner-bg;
            }
          }
        }
      }

      &:after {
        border-top-color: $video-menu-bg;
      }
    }

    &.plyr--playing {}

    &.plyr--paused {}

    &.plyr--stopped {
      .plyr__poster {
        display: block;
      }
    }
  }
}
</style>
